<template>
    <div class="basic_data_edit">
        <div class="data_content data-content-form scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <div class="formBody">
                <!-- 预警设置 -->
                <div class="yjsz-title">预警设置</div>
                <div class="yjsz-header">
                    <div class="yjsz-item-flex">
                        <div class="yjsz-label">对象名称：</div>
                        <div class="yjsz-value nowrap-ellipsis">
                            {{ yjszList.DXMC }}
                        </div>
                    </div>
                </div>
                <!-- 预警负责人 -->
                <div class="yjsz-item-flex yjfzr-border">
                    <div class="yjsz-label">
                        <span style="color: #f56c6c; margin-right: 4px">*</span>
                        <span>预警负责人</span>
                    </div>
                    <div class="yjsz-value">
                        <el-select
                            v-model="yjszList.CZFZRID"
                            filterable
                            class="yjsz-select"
                            placeholder="请选择预警负责人"
                            :disabled="!isEdit"
                        >
                            <el-option
                                v-for="(item, index) in yjfzrList"
                                :key="index"
                                :label="item.REALNAME"
                                :value="item.ID"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div
                    v-for="(item, index) in yjszList.YJPZLIST"
                    :key="index"
                    class="yjzs-body"
                >
                    <!-- 一级预警标签 -->
                    <div class="yjzs-body-title">{{ item.label }}</div>
                    <!-- 预警指标-枚举类 -->
                    <div v-if="sjlx == '2'" class="yjsz-item-flex">
                        <div class="yjsz-label">{{ yjzbLabel }}</div>
                        <div class="yjsz-value">
                            <el-checkbox-group
                                v-model="item.pgids"
                                :disabled="!isEdit"
                            >
                                <el-checkbox
                                    v-for="(itemInner, indexInner) in yjzbList"
                                    :key="'yjzb' + indexInner + itemInner.CODE"
                                    :label="itemInner.CODE"
                                    >{{ itemInner.NAME }}</el-checkbox
                                >
                            </el-checkbox-group>
                        </div>
                    </div>
                    <!-- 预警指标-数值类 -->
                    <div v-if="sjlx == '1'">
                        <div class="yjsz-item-flex express-top">
                            <!-- 符号 -->
                            <div
                                v-for="(itemInner, indexInner) in operatorList"
                                :key="indexInner"
                                class="operator-btn"
                                @click="
                                    handleExpressChanged(index, itemInner.YWM)
                                "
                            >
                                {{ itemInner.YWM }}
                            </div>
                            <div class="vertical-line"></div>
                            <!-- 预警指标 -->
                            <div
                                v-for="(itemInner, indexInner) in yjzbSzlList"
                                :key="'yjzbSzlList' + indexInner"
                                class="yjzs-btn"
                                @click="
                                    handleExpressChanged(index, itemInner.NAME)
                                "
                            >
                                {{ itemInner.NAME }}
                            </div>
                        </div>
                        <el-input
                            :id="`input_${index}`"
                            v-model="item.formula"
                            class="express-bottom"
                            type="textarea"
                            :rows="4"
                            placeholder=""
                            :disabled="!isEdit"
                        >
                        </el-input>
                    </div>
                    <!-- 预警信息接收人 -->
                    <div class="yjsz-item-flex">
                        <div class="yjsz-label">预警信息接收人</div>
                        <div class="yjsz-value-short">
                            <el-select
                                v-model="item.yjxxjsr"
                                filterable
                                class="yjsz-select"
                                placeholder="请选择预警信息接收人"
                                multiple
                                :disabled="!isEdit"
                            >
                                <el-option
                                    v-for="(itemInner, indexInner) in yjfzrList"
                                    :key="indexInner"
                                    :label="itemInner.REALNAME"
                                    :value="itemInner.ID"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="yjsz-value-short">
                            <el-radio-group
                                v-model="item.sendNowState"
                                :disabled="!isEdit"
                            >
                                <el-radio :label="0"
                                    >预警后立即下发消息</el-radio
                                >
                                <el-radio :label="1"
                                    >预警核实后下发消息</el-radio
                                >
                            </el-radio-group>
                        </div>
                    </div>
                    <!-- 预警预案 -->
                    <div class="yjsz-item-flex">
                        <div class="yjsz-label">预警预案</div>
                        <div class="yjsz-value">
                            <el-select
                                v-model="item.yaid"
                                filterable
                                class="yjsz-select"
                                placeholder="请选择预警预案"
                                clearable
                                :disabled="!isEdit"
                            >
                                <el-option
                                    v-for="(item3, index3) in yjyaList[index]"
                                    :key="'yjya' + index3 + item3.ID"
                                    :label="item3.YAMC"
                                    :value="item3.ID"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import snButton from "../warning_common/edit-button.js";
import infoMixin from "../warning_common/info_mixin.js";
import { mapActions } from "vuex";
export default {
    name: "warning_plan_form",
    mixins: [infoMixin],
    data() {
        return {
            notNeedFile: true,
            snButton: snButton,
            sjlx: "", // 数据类型:1数值类，2枚举类
            yjfzrList: [], // 预警负责人列表
            yjszList: {
                // 预警设置列表
                DXMC: "", //对象名称
                CZFZRID: "", //预警负责人
                YJPZLIST: [
                    {
                        pgids: [], //评估id
                        yjxxjsr: [], //预警信息接收人
                        label: "一级预警",
                        yaid: "", //预案id
                        sendNowState: 0, //预警后是否立即下发预警消息(1是)
                        BDS: "", //数值类的时候，公式存在这
                        yjdjid: "", //预警等级id
                        formula: "", //公式
                        pzid: "", //配置id
                    },
                    {
                        pgids: [],
                        yjxxjsr: [], //预警信息接收人
                        label: "二级预警",
                        yaid: "",
                        sendNowState: 0,
                        BDS: "",
                        yjdjid: "", //预警等级id
                        formula: "", //公式
                        pzid: "",
                    },
                    {
                        pgids: [],
                        yjxxjsr: [], //预警信息接收人
                        label: "三级预警",
                        yaid: "",
                        sendNowState: 0,
                        BDS: "",
                        yjdjid: "", //预警等级id
                        formula: "", //公式
                        pzid: "",
                    },
                    {
                        pgids: [],
                        yjxxjsr: [], //预警信息接收人
                        label: "四级预警",
                        yaid: "",
                        sendNowState: 0,
                        BDS: "",
                        yjdjid: "", //预警等级id
                        formula: "", //公式
                        pzid: "",
                    },
                ],
            },
            yjzbChecked: [[], [], [], []],
            yjzbLabel: "",
            yjzbList: [], // 预警指标列表
            yjjsrSelected: [[], [], [], []], //预警信息接收人
            yjyaList: [[], [], [], []], // 预警预案列表

            operatorList: [], // 运算符号
            yjzbSzlList: [], // 数值类预警指标
            zbxNameListStr: "",
        };
    },
    created() {
        // 预警预案附件列表
        this.getYjyaList();
        this.getUserList();
        let obj = JSON.parse(this.$route.query.data);
        this.sjlx = obj.SJLX.toString();
        // 数据类型 1数值类，2枚举类
        if (this.sjlx === "1") {
            this.getOperationSymbol();
            this.yjzbSzlList = [];
            if (obj.YJZBXZJGZDMC && obj.YJZBXZJGZSMC) {
                this.zbxNameListStr = obj.YJZBXZJGZSMC;
                let codeArr = obj.YJZBXZJGZDMC.split(",");
                let nameArr = obj.YJZBXZJGZSMC.split(",");
                if (codeArr.length > 0 && nameArr.length > 0) {
                    codeArr.map((item, index) => {
                        let obj = {
                            CODE: item,
                            NAME: nameArr[index],
                        };
                        this.yjzbSzlList.push(obj);
                    });
                }
            }
        } else {
            // 获取预警指标
            this.getPgjgInfo(obj.ENUMID, obj.DXLXBM);
        }
    },
    mounted() {
        this.snButton.buttonData[1].isShow = false;
        let obj = JSON.parse(this.$route.query.data);
        this.sjlx = obj.SJLX.toString();
        this.yjszList.DXMC = obj.DXMC; //对象名称
    },
    methods: {
        ...mapActions([
            "GetYjyaList", // 获取预警预案附件列表
            "GetUserList", // 获取人员列表
            "GetYjxxPgjgList", // 获取枚举类指标结果
            "GetYjpzDetail", // 获取预警配置详情
            "SaveYjpzData", // 保存预警配置数据
            "GetOperationSymbol", // 获取运算符号
            "ValidExpression", // 验证表达式是否可用
        ]),
        getFromData() {
            // this.type  0 配置 1 修改 2 查看
            if (this.type !== "0") {
                this.getYjpzDetail();
            }
        },
        // 获取预警预案附件列表
        async getYjyaList() {
            let result = await this.GetYjyaList({
                // yjdj: index.toString(), // string，预警等级
            });
            this.yjyaList[0] = [];
            this.yjyaList[1] = [];
            this.yjyaList[2] = [];
            this.yjyaList[3] = [];
            result.map((item, index) => {
                switch (item.YJDJ) {
                    case 0:
                        this.yjyaList[0].push(item);
                        break;
                    case 1:
                        this.yjyaList[1].push(item);
                        break;
                    case 2:
                        this.yjyaList[2].push(item);
                        break;
                    case 3:
                        this.yjyaList[3].push(item);
                        break;
                }
            });
        },
        // 获取人员列表
        async getUserList() {
            this.yjfzrList = [];
            let res = await this.GetUserList({
                keywords: "",
                depID: "",
                roleid: "",
                pageindex: 1,
                pagesize: 99999,
            });
            if (
                res &&
                res.result &&
                res.result.Data &&
                res.result.Data.length > 0
            ) {
                // res.result.Data.map((item, index) => {
                //     if (item.ID == "32eaa15e-2a8c-40d3-9682-740a3cda1d5b") {
                //         res.result.Data.splice(index, 1);
                //     }
                // });
                this.yjfzrList = res.result.Data;
            }
        },
        // 获取枚举类指标结果
        async getPgjgInfo(enumId, itemCode) {
            this.yjzbLabel = "";
            this.yjzbList = [];
            let obj = JSON.parse(this.$route.query.data);
            let result = await this.GetYjxxPgjgList({
                enumId: enumId,
                itemCode: itemCode,
                lx: obj.LX ? obj.LX : "",
                sjlx: this.sjlx,
            });
            if (result) {
                this.yjzbLabel = result.PGJG;
                this.yjzbList = result.ENUMDATA;
            }
        },
        // 获取预警配置详情
        async getYjpzDetail() {
            let result = await this.GetYjpzDetail({
                yjpzId: this.id, // string，预警配置ID
                sjlx: this.sjlx, // string，数据类型:1数值类，2枚举类
            });
            // console.log("获取预警配置详情", result);
            if (result && result.length > 0) {
                let obj = result[0];
                this.yjszList.YJPZLIST.map((item, index) => {
                    obj.YJPZLIST.map((itemInner, indexInner) => {
                        if (itemInner.YJDJ == index) {
                            this.yjszList.YJPZLIST[index].pgids = itemInner.PGJG
                                ? itemInner.PGJG.split(",")
                                : ""; //评估id
                            this.yjszList.YJPZLIST[index].yjxxjsr =
                                itemInner.YJTZRID
                                    ? itemInner.YJTZRID.split(",")
                                    : []; //预警信息接收人
                            this.yjszList.YJPZLIST[index].yaid =
                                itemInner.YJYAID; //预案id
                            this.yjszList.YJPZLIST[index].sendNowState =
                                itemInner.SFLJXFYJXX; //预警后是否立即下发预警消息(1是)
                            this.yjszList.YJPZLIST[index].id = itemInner.ID;
                            this.yjszList.YJPZLIST[index].pzid = itemInner.PZID;
                            this.yjszList.YJPZLIST[index].formula =
                                itemInner.BDS ? itemInner.BDS : ""; //数值类的时候，公式存在这
                        }
                    });
                });
                this.yjszList.CZFZRID = obj.CZFZRID; //预警负责人
                this.yjszList.YJPZLIST.map((item, index) => {
                    // 预警信息接收人 不存在 清空选中
                    item.yjxxjsr.map((itemIner, indexInner) => {
                        obj = this.common.aryListFindOne(
                            this.yjfzrList,
                            "ID",
                            itemIner
                        );
                        if (!obj.ID) {
                            item.yjxxjsr.splice(indexInner, 1);
                        }
                    });
                    // 预案 不存在 清空选中
                    obj = this.common.aryListFindOne(
                        this.yjyaList[index],
                        "ID",
                        item.yaid
                    );
                    if (!obj.ID) {
                        item.yaid = "";
                    }
                });
                // 预警负责人 不存在 清空选中
                obj = this.common.aryListFindOne(
                    this.yjfzrList,
                    "ID",
                    this.yjszList.CZFZRID
                );
                // 单位名称删除
                if (!obj.ID) {
                    this.yjszList.CZFZRID = "";
                }
            }
        },
        //保存
        saveNosubmit() {
            if (!this.yjszList.CZFZRID) {
                this.common.showMsg("请选择预警负责人", "warning");
                return;
            }
            // 数据类型 1数值类，2枚举类
            if (this.sjlx === "1") {
                this.testSzl();
            } else {
                this.testMjl();
            }
        },
        // 数值类条件检查
        testSzl() {
            if (
                !this.yjszList.YJPZLIST[0].formula &&
                !this.yjszList.YJPZLIST[1].formula &&
                !this.yjszList.YJPZLIST[2].formula &&
                !this.yjszList.YJPZLIST[3].formula
            ) {
                this.common.showMsg("至少选择一个预警等级规则项", "warning");
                return;
            }

            let state = true;
            let errorStr = "";
            // 检测选择  预警信息接收人，预警预案，却没有选择规则
            this.yjszList.YJPZLIST.map((item, index) => {
                if ((item.yaid || item.yjxxjsr.length) && !item.formula) {
                    errorStr += `${item.label}${this.yjzbLabel},`;
                    state = false;
                }
            });

            if (state) {
                // 验证表达式
                this.testExpress().then((res) => {
                    console.log("res", res);
                    if (res) {
                        this.upDataSzl();
                    }
                });
            } else {
                this.common.showMsg(errorStr, "warning");
            }
        },
        // 枚举类条件检查
        testMjl() {
            if (
                this.yjszList.YJPZLIST[0].pgids.length == 0 &&
                this.yjszList.YJPZLIST[1].pgids.length == 0 &&
                this.yjszList.YJPZLIST[2].pgids.length == 0 &&
                this.yjszList.YJPZLIST[3].pgids.length == 0
            ) {
                this.common.showMsg("至少选择一个预警等级评估项", "warning");
                return;
            }
            let state = true;
            this.yjszList.YJPZLIST.map((item, index) => {
                if (
                    (item.yaid || item.yjxxjsr.length) &&
                    item.pgids.length == 0
                ) {
                    this.common.showMsg(
                        `请选择${item.label}${this.yjzbLabel}`,
                        "warning"
                    );
                    state = false;
                }
            });
            if (state) {
                this.upData();
            }
        },
        // 检测表达式
        async testExpress() {
            let state = true;
            let formula0 = this.yjszList.YJPZLIST[0].formula;
            let ressult0 = {};
            let formula1 = this.yjszList.YJPZLIST[1].formula;
            let ressult1 = {};
            let formula2 = this.yjszList.YJPZLIST[2].formula;
            let ressult2 = {};
            let formula3 = this.yjszList.YJPZLIST[3].formula;
            let ressult3 = {};
            if (formula0) {
                ressult0 = await this.ValidExpression({
                    expression: formula0, // 表达式
                    zbx: this.zbxNameListStr, // 指标项，多个用逗号分隔
                });
                if (!ressult0.ISSUCCESS) {
                    this.common.showMsg("一级预警公式错误", "warning");
                    state = false;
                }
            }

            if (formula1) {
                ressult1 = await this.ValidExpression({
                    expression: formula1, // 表达式
                    zbx: this.zbxNameListStr, // 指标项，多个用逗号分隔
                });
                if (!ressult1.ISSUCCESS) {
                    this.common.showMsg("二级预警公式错误", "warning");
                    state = false;
                }
            }

            if (formula2) {
                ressult2 = await this.ValidExpression({
                    expression: formula2, // 表达式
                    zbx: this.zbxNameListStr, // 指标项，多个用逗号分隔
                });
                if (!ressult2.ISSUCCESS) {
                    this.common.showMsg("三级预警公式错误", "warning");
                    state = false;
                }
            }

            if (formula3) {
                ressult3 = await this.ValidExpression({
                    expression: formula3, // 表达式
                    zbx: this.zbxNameListStr, // 指标项，多个用逗号分隔
                });
                if (!ressult3.ISSUCCESS) {
                    this.common.showMsg("四级预警公式错误", "warning");
                    state = false;
                }
            }
            return state;
        },
        // 枚举类上传
        async upData() {
            let routeData = JSON.parse(this.$route.query.data);
            let list = [];
            this.yjszList.YJPZLIST.map((item, index) => {
                if (item.pgids.length) {
                    let name = [];
                    this.yjfzrList.map((itemJsr, indexJsr) => {
                        item.yjxxjsr.map((itemGet, indexGet) => {
                            if (itemGet == itemJsr.ID) {
                                name.push(itemJsr.REALNAME);
                            }
                        });
                    });

                    let obj = {
                        ID: item.id,
                        PZID: item.pzid,
                        BDS: "", //数值类 公式存
                        PGJG: item.pgids.join(","), //枚举类的时候，吧评估结果的枚举
                        YJDJ: index, //0 一级预警
                        YJTZRID: item.yjxxjsr.join(","), //预警信息接收人的ID
                        YJTZRMC: name.join(","), //预警等级的ID
                        YJYAID: item.yaid, //预案ID
                        CJSJ: this.common.getTimeYmdhms(), //当前时间  年月日时分秒
                        SFLJXFYJXX: item.sendNowState, //预警后是否立即下发预警消息(1是
                    };
                    list.push(obj);
                }
            });

            let czfzrmc = this.common.aryListFindOne(
                this.yjfzrList,
                "ID",
                this.yjszList.CZFZRID
            ).REALNAME;
            let jsonStr = {
                // 预警设置 基本
                ID: routeData.ID,
                YJPZFAMC: routeData.YJPZFAMC, //预警配置方案名称
                CZFZRID: this.yjszList.CZFZRID, //处置负责人ID
                CZFZRMC: czfzrmc, //处置负责人名称
                YJXXSM: routeData.YJXXSM, //预警信息说明 该出 已隐藏 传""
                YJXID: routeData.YJXID, //预警项ID
                YCYSBM: routeData.YCYSBM, //遗产要素编码
                SJLX: routeData.SJLX, //1数值类，2枚举类'
                PZZT: 2, //配置ID 0未配置 1已暂停 2实施中
                //每一级预警的信息
                YJPZLIST: list,
            };
            console.log("jsonStr", jsonStr);
            let res = await this.SaveYjpzData({
                jsonStr: JSON.stringify(jsonStr),
                sjlx: this.sjlx,
            });
            if (res.ISSUCCESS) {
                this.common.showMsg(res.RESULTDESCRIPTION, "success");
                this.returnList();
            } else {
                this.common.showMsg(res.RESULTDESCRIPTION, "warning");
            }
        },
        // 数值类上传
        async upDataSzl() {
            let routeData = JSON.parse(this.$route.query.data);
            let list = [];
            this.yjszList.YJPZLIST.map((item, index) => {
                if (item.formula.length) {
                    let name = [];
                    this.yjfzrList.map((itemJsr, indexJsr) => {
                        item.yjxxjsr.map((itemGet, indexGet) => {
                            if (itemGet == itemJsr.ID) {
                                name.push(itemJsr.REALNAME);
                            }
                        });
                    });

                    let obj = {
                        ID: item.id,
                        PZID: item.pzid,
                        BDS: item.formula, //数值类 公式存
                        YJDJ: index, //0 一级预警
                        YJTZRID: item.yjxxjsr.join(","), //预警信息接收人的ID
                        YJTZRMC: name.join(","), //预警等级的ID
                        YJYAID: item.yaid, //预案ID
                        CJSJ: this.common.getTimeYmdhms(), //当前时间  年月日时分秒
                        SFLJXFYJXX: item.sendNowState, //预警后是否立即下发预警消息(1是
                    };
                    list.push(obj);
                }
            });
            let czfzrmc = this.common.aryListFindOne(
                this.yjfzrList,
                "ID",
                this.yjszList.CZFZRID
            ).REALNAME;
            let jsonStr = {
                // 预警设置 基本
                ID: routeData.ID ? routeData.ID : "",
                YJPZFAMC: routeData.YJPZFAMC, //预警配置方案名称
                CZFZRID: this.yjszList.CZFZRID, //处置负责人ID
                CZFZRMC: czfzrmc, //处置负责人名称
                YJXXSM: routeData.YJXXSM, //预警信息说明 该出 已隐藏 传""
                YJXID: routeData.YJXID, //预警项ID
                YCYSBM: routeData.YCYSBM, //遗产要素编码
                SJLX: routeData.SJLX, //1数值类，2枚举类'
                PZZT: 2, //配置ID 0未配置 1已暂停 2实施中
                //每一级预警的信息
                YJPZLIST: list,
            };
            console.log("jsonStr", jsonStr);
            let res = await this.SaveYjpzData({
                jsonStr: JSON.stringify(jsonStr),
                sjlx: this.sjlx,
            });
            if (res.ISSUCCESS) {
                this.common.showMsg(res.RESULTDESCRIPTION, "success");
                this.returnList();
            } else {
                this.common.showMsg(res.RESULTDESCRIPTION, "warning");
            }
        },
        // 获取运算符号
        async getOperationSymbol() {
            this.operatorList = [];
            let result = await this.GetOperationSymbol();
            if (result && result.length > 0) {
                this.operatorList = result;
            }
        },
        // 表达式
        handleExpressChanged(index, val) {
            if (this.isEdit && val) {
                // this.yjszList.YJPZLIST[index].formula += val;
                let id = `input_${index}`;
                let elInput = document.getElementById(id);
                let startPos = elInput.selectionStart;
                let endPos = elInput.selectionEnd;
                if (startPos === undefined || endPos === undefined) return;
                let txt = elInput.value;
                let result =
                    txt.substring(0, startPos) + val + txt.substring(endPos);
                elInput.value = result;
                elInput.focus();
                elInput.selectionStart = startPos + val.length;
                elInput.selectionEnd = startPos + val.length;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.data-content-form {
    width: 100%;
    height: 100%;
    .formBody {
        width: 1000px;
        height: 100%;
        margin: 0 auto;
        .yjsz-title {
            padding: 15px 0;
            border-bottom: 1px solid #d1d1d0;
            margin-bottom: 20px;
            color: #4d5b6e;
            font-size: 15px;
            font-weight: bold;
        }
        .yjsz-header {
            padding: 20px 0;
            background: #d4e6ea;
            margin-bottom: 15px;
        }
        .yjsz-item-flex {
            display: flex;
            line-height: 30px;
            margin: 10px 0;
        }
        .yjsz-label {
            width: 120px;
            text-align: right;
        }
        .yjsz-value {
            width: calc(100% - 140px);
            margin-left: 20px;
        }
        .yjsz-value-short {
            width: calc(50% - 80px);
            margin-left: 20px;
        }
        .nowrap-ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .yjfzr-border {
            margin-bottom: 20px;
        }
        .yjzs-body {
            border-top: 1px solid #d1d1d0;
            padding: 15px 0;
            .yjzs-body-title {
                font-weight: bold;
            }
        }

        .express-top {
            display: flex;
            align-items: center;
            height: 40px;
            padding: 0 6px;
            border: 1px solid #dddddb;
            margin-bottom: -1px;
            .operator-btn {
                // display: inline-block;
                height: 22px;
                line-height: 22px;
                min-width: 22px;
                text-align: center;
                padding: 0 5px;
                margin-right: 6px;
                border-radius: 2px;
                background: #fff;
                box-shadow: 1px 1px 4px #888888;
                cursor: pointer;
            }
            .vertical-line {
                height: 26px;
                width: 2px;
                margin: 0 16px 0 10px;
                background: #dedede;
            }
            .yjzs-btn {
                // display: inline-block;
                height: 26px;
                line-height: 25px;
                text-align: center;
                padding: 0 10px;
                margin-right: 10px;
                border-radius: 4px;
                font-size: 12px;
                color: #fff;
                background: #2962d7;
                cursor: pointer;
            }
        }
    }
}
</style>
